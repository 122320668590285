import { TicketGroup } from '@hooks/use-tevomaps';
import { LeagueEvent } from '@interfaces/APITravel';
import { type TicketSortBy, useTicketsStore } from '@store/tickets-store';
import { CurrencyDropdownV2 } from '@v2/containers/CurrencyPicker/CurrencyDropdownV2';
import {
  Button,
  Checkbox,
  Close,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  FilterFunnel01,
  Label,
  Map02,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  typographyVariants,
} from '@v2/ui';
import { cn } from '@v2/utils';
import React from 'react';
import { ArenaView } from './ArenaView';
import { TicketPriceRangeSlider } from './TicketPriceRangeSlider';

interface MobileMapFilterProps {
  event: LeagueEvent;
  ticketGroups: TicketGroup[];
}
export function MobileMapFilter({ event, ticketGroups }: MobileMapFilterProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Dialog open={isOpen} onOpenChange={(val) => setIsOpen(val)}>
      <DialogTrigger asChild>
        <Button size={'md'} className="w-full bg-white" variant="secondary">
          <Map02 className="mr-2" />
          Stadium
        </Button>
      </DialogTrigger>

      <DialogContent
        showCloseButton={false}
        className="flex h-screen max-h-[calc(100dvh)] w-full flex-col rounded-none p-0 pb-[env(safe-area-inset-bottom)] md:w-screen md:max-w-[100vw]"
      >
        <DialogHeader className="border-b border-gray-300">
          <div className="flex flex-row items-center justify-between p-4">
            <button
              className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-300"
              onClick={() => setIsOpen(false)}
            >
              <Close className="text-gray-800" size="16" />
            </button>
            <CurrencyDropdownV2 className="m-0 p-0" />
          </div>
        </DialogHeader>

        <ArenaView event={event} ticketGroups={ticketGroups} />
      </DialogContent>
    </Dialog>
  );
}

const ticketSortingoptions = [
  { name: 'Lowest price', value: 'lowestPrice' },
  { name: 'Best seats', value: 'bestSeats' },
];

export function MobileTicketFilters({
  ticketsList,
}: {
  ticketsList: TicketGroup[];
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleBoolean = useTicketsStore((state) => state.toggleBoolean);
  const isOnlyElectronic = useTicketsStore((state) => state.isOnlyElectronic);

  const sortBy = useTicketsStore((state) => state.sortby);
  const setSortBy = useTicketsStore((state) => state.setSortBy);

  return (
    <Dialog open={isOpen} onOpenChange={(val) => setIsOpen(val)} modal={false}>
      <DialogTrigger asChild>
        <Button size={'md'} className="w-full bg-white" variant="secondary">
          <FilterFunnel01 className="mr-2" />
          Sort & Filter
        </Button>
      </DialogTrigger>

      <DialogContent
        showCloseButton={false}
        className="flex h-screen max-h-[calc(100dvh)] w-full flex-col rounded-none p-0 md:w-screen md:max-w-[100vw]"
      >
        <DialogHeader className="border-b border-gray-300">
          <div
            className="flex items-center justify-start gap-x-3 p-4"
            onClick={() => setIsOpen(false)}
          >
            <button
              className="rounded-full border border-gray-300 p-2"
              onClick={() => setIsOpen(false)}
            >
              <Close className="text-gray-800" />
            </button>
            {/* </DialogClose> */}
            <DialogTitle
              className={cn(
                typographyVariants({ size: 'tsm' }),
                'font-bold md:font-semibold'
              )}
            >
              Sort & Filters
            </DialogTitle>
          </div>
        </DialogHeader>

        <div className="space-y-6 p-4">
          <div>
            <h3 className="text-md mb-3 font-bold text-gray-800">
              Price range
            </h3>
            <Select
              value={sortBy}
              onValueChange={(value) => setSortBy(value as TicketSortBy)}
            >
              <SelectTrigger className="w-full bg-white">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent>
                {ticketSortingoptions.map((item) => (
                  <SelectItem
                    key={item.value}
                    className="justify-between"
                    value={item.value}
                  >
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div>
            <h3 className="text-md mb-3 font-bold text-gray-800">
              {' '}
              Price range
            </h3>
            <TicketPriceRangeSlider ticketsList={ticketsList} />
          </div>

          <div>
            <h3 className="text-md mb-3 font-bold text-gray-800">
              Ticket type
            </h3>
            <div className="flex items-center gap-x-3">
              <Checkbox
                className="rounded-md bg-white"
                size="sm"
                checked={isOnlyElectronic}
                onCheckedChange={() => toggleBoolean('isOnlyElectronic')}
              />
              <Label className="font-semibold text-gray-800">
                Electronic tickets only
              </Label>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
