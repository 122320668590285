import routes from '@config/routes';
import { useCheckoutStepsStore } from '@store/checkout-steps-store';
import { getShoppingCartById } from '@v2/action/shopping-cart/shopping-cart-action';
import { CheckoutPayload } from '@v2/action/shopping-cart/types';
import useSWR, { mutate, SWRResponse } from 'swr';

export function useGetShoppingCartById(): SWRResponse<CheckoutPayload> {
  const shoppingCartId = useCheckoutStepsStore((state) => state.cartId);

  const data = useSWR<CheckoutPayload>(
    shoppingCartId ? routes.shoppingCartId(shoppingCartId) : null,
    (url) => getShoppingCartById(shoppingCartId),
    { revalidateIfStale: true, revalidateOnFocus: false }
  );

  return data;
}

export async function prefetchShoppingCartById(shoppingCartId: string) {
  if (!shoppingCartId) return;

  const key = routes.shoppingCartId(shoppingCartId);

  mutate(
    key,
    async () => {
      const data = await getShoppingCartById(shoppingCartId);
      return data;
    },
    { revalidate: false }
  );
}
