'use client';
import * as Dialog from '@radix-ui/react-dialog';
import { cn } from '@v2/utils';
import { Suspense, useEffect } from 'react';
import { useBuildPackagesStore } from 'src/stores/build-package-store';

import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';
import { LeagueEvent } from '@interfaces/APITravel';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { useLockBodyScroll } from '@uidotdev/usehooks';
import { PageLoader } from '@v2/components';
import { Close, Skeleton } from '@v2/ui';
import { useMediaQuery, useWindowSize } from 'usehooks-ts';
import { Drawer } from 'vaul';
import {
  Header,
  HotelItem,
  HotelsContent,
  SidebarHotelFilters,
} from './components';
import { MobileBottomHeader } from './components/HotelContent/MobileBottomHeader';
import { MapView } from './components/MapView';
import { useGetHotels } from './hooks/use-hotels-list';

type Props = {
  event: LeagueEvent;
  eventId: string;
};

export function SearchHotelView({ event }: Props) {
  useEffect(() => {
    useBuildPackagesStore.persist.rehydrate();
  }, []);
  useScrollToTopOnMount();
  const hasHydrated = useBuildPackagesStore((state) => state._hasHydrated);
  const matches = useMediaQuery('(min-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 400px)');
  const isMapFullScreen = useSearchHotelStore((state) => state.isMapFullScreen);
  const { height } = useWindowSize();

  const { isValidating } = useGetHotels();

  if (!hasHydrated || isValidating) return <PageLoader />;

  return (
    <Suspense fallback={<PageLoader />}>
      <section className="buy-package-height pt-2 md:mx-auto md:max-w-[1400px] md:px-20 md:pt-12">
        <div className="pb-4 md:pb-8">
          <Header event={event} />
          {isMapFullScreen && <DialogMapView />}
          <div className="flex h-auto flex-col-reverse gap-x-12 rounded-lg px-4 md:items-start md:px-4 lg:flex-row">
            <>
              {' '}
              {matches && (
                <aside
                  className={cn(
                    'top-2 h-full w-full shrink-0 md:w-[200px] xl:w-[300px]',
                    height > 1000 ? 'sticky' : 'relative'
                  )}
                >
                  <SidebarHotelFilters />
                </aside>
              )}
              <div className="h-full w-full md:w-full">
                <HotelsContent />
              </div>
              {isMobile && <MobileBottomHeader />}
            </>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

function DialogMapView() {
  const hotelDrawerSelected = useSearchHotelStore(
    (state) => state.hotelDrawerSelected
  );
  const isMapFullScreen = useSearchHotelStore((state) => state.isMapFullScreen);

  useLockBodyScroll();

  const matches = useMediaQuery('(min-width: 1024px)');

  return (
    <>
      <Dialog.Root open={isMapFullScreen} modal={false}>
        <Dialog.Portal>
          <Dialog.Content
            className={cn(
              'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-[50%] top-[50%] z-20 grid h-screen w-screen translate-x-[-50%] translate-y-[-50%] gap-4 rounded-xl border bg-white pt-10 shadow-shadowLg duration-200'
            )}
          >
            <Suspense
              fallback={<Skeleton className="z-20 h-full w-full bg-white" />}
            >
              <MapView className="h-screen min-w-full" />
            </Suspense>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <Drawer.Root
        modal={false}
        open={hotelDrawerSelected.isOpen}
        onOpenChange={(val) =>
          useSearchHotelStore.setState({
            hotelDrawerSelected: {
              isOpen: val,
              hotelId: hotelDrawerSelected.hotelId,
            },
          })
        }
      >
        <Drawer.Portal>
          <Drawer.Content className="fixed bottom-20 left-0 right-0 z-30 mx-auto w-11/12 rounded-t-[10px] md:w-fit">
            {matches && (
              <Drawer.Close className="absolute right-6 top-6">
                <Close />
              </Drawer.Close>
            )}
            <DialogContent />
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </>
  );
}
function DialogContent() {
  const hotelId = useSearchHotelStore(
    (state) => state.hotelDrawerSelected.hotelId
  );

  const hotel = useGetHotels().data.find((item) => item.id === hotelId)!;
  return (
    <HotelItem
      {...hotel}
      className="shadow-shadowLg"
      onItemClick={(e) => {
        useSearchHotelStore.setState({
          hotelDrawerSelected: {
            isOpen: false,
            hotelId: null,
          },
          isMapFullScreen: false,
        });
      }}
    />
  );
}
