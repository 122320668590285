import { TrackedEvents } from '@config/index';
import routes from '@config/routes';
import { PACKAGE_STORAGE } from '@config/storageKeys';
import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';
import { TicketGroup } from '@hooks/use-tevomaps';
import useStorage from '@hooks/useStorage';
import instance from '@instance';
import { LeagueEvent } from '@interfaces/APITravel';
import { IFormValues } from '@interfaces/buildPackage';
import { OrderTicketTaxQuotaRequest } from '@interfaces/ticketevolution/tikets';
import { getTicketsTaxQuotes } from '@pages/api/te/tax';
import { useBuildPackagesStore } from '@store/build-package-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { ArrowLeft, Button, Close, Divider, Phone02, Typography } from '@v2/ui';
import { cn } from '@v2/utils';
import { getCookie } from 'cookies-next';
import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';
import { useMediaQuery } from 'usehooks-ts';

type Props = {
  event: LeagueEvent;
  ticketGroups: TicketGroup[];
};

type PackageDataType = IFormValues;

export function ConfirmSelection(props: Props) {
  useScrollToTopOnMount();
  const storage = useStorage();

  const { data: sessionData } = useSession();
  const getDataFromStorage = (name) => {
    const data = storage.getItem(name);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const { priceRate, currency } = useCurrencyContext();

  const [ticketSelected, setTicketSelected] = useTicketsStore((state) => [
    state.ticketSelected,
    state.setTicketSelected,
  ]);

  const [packageData, setPackageData] = useState<PackageDataType>(
    getDataFromStorage(PACKAGE_STORAGE)
  );

  const isMobile = useMediaQuery('(max-width: 768px)');

  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);

  const [loading, setLoading] = useState(false);

  const isBundle = useBuildPackagesStore((state) => state.isBundle);

  const [isTicketSelectionConfirmed, setTicketSelectionConfirmed] =
    useTicketsStore((state) => [
      state.isTicketSelectionConfirmed,
      state.setTicketSelectionConfirmed,
    ]);

  if (!ticketSelected) return null;
  const { section, retail_price, row } = ticketSelected!;

  const handleOnCancelSelection = () => {
    setTicketSelectionConfirmed({
      isTicketSelectionConfirmed: false,
      taxQuota: {
        tax: 0,
        tax_signature: null,
      },
    });
    setTicketSelected(null);
  };

  const handleConfirmSelection = async () => {
    setLoading(true);
    const { id: ticketGroupId } = ticketSelected;
    const taxQuotaRequest = {
      ticketGroupId,
      quantity: ticketsQuantity,
    } as OrderTicketTaxQuotaRequest;

    const taxQuota = await getTicketsTaxQuotes(taxQuotaRequest);

    const tax =
      Number(taxQuota?.wholesale?.tax || 0) +
      Number(taxQuota?.wholesale?.additional_expense || 0) +
      Number(taxQuota?.wholesale?.service_fee || 0) +
      Number(taxQuota?.wholesale?.shipping || 0);

    const temp = packageData;
    temp.selected['tickets'] = true;
    setPackageData({ ...temp });
    storage.setItem(PACKAGE_STORAGE, JSON.stringify(temp));

    const userIp = getCookie('user-ip')?.toString() || 'IP not found';

    const anonymousId =
      getCookie('anonymous-user-id')?.toString() ||
      'anonymous-user-id not found';

    const user = sessionData?.user;
    instance.server(routes.track, {
      body: JSON.stringify({
        userId: user?.id ? Number(user.id) : undefined,
        anonymousId: user ? undefined : anonymousId,
        trackedEvent: TrackedEvents.TICKET_SELECTED,
        properties: JSON.stringify({
          ip: userIp,
          anonymousId,
          eventName: packageData.eventName,
          eventDate: packageData.eventDate,
          ticketCount: packageData.tickets,
          hotelEndDate: packageData.hotelEndDate,
          hotelStartDate: packageData.hotelStartDate,
          ticketSection: section,
          ticketRow: row,
          retailPrice: retail_price,
        }),
      }),
      method: 'POST',
    });

    setLoading(false);
    setTicketSelectionConfirmed({
      isTicketSelectionConfirmed: true,
      taxQuota: {
        tax,
        tax_signature: taxQuota?.tax_signature,
      },
    });
  };

  const packagePrice =
    (retail_price * ticketsQuantity - (retail_price ?? 0)) / ticketsQuantity;

  const finalPrice = isBundle ? packagePrice : retail_price;

  return (
    <div
      className={cn(
        'h-full w-full bg-white',
        isMobile && 'absolute top-0 z-30'
      )}
    >
      <div className="flex w-full flex-row-reverse items-center justify-end border-b border-gray-200 p-4 md:flex-row md:justify-between">
        <Typography size="tmd" className="font-semibold">
          Confirm selection
        </Typography>
        <Button
          variant="ghost"
          onClick={handleOnCancelSelection}
          className="p-0"
        >
          {isMobile ? (
            <ArrowLeft pathProps={{ stroke: '#1D2939' }} />
          ) : (
            <Close size="20" />
          )}
        </Button>
      </div>
      <div className="space-y-6 p-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-y-1 text-start">
            <Typography
              size="txl"
              className="max-w-[15ch] truncate font-bold md:max-w-[20ch]"
            >
              Section {section}, Row {row}
            </Typography>
            <Typography size="tsm" className="font-medium text-gray-500">
              {ticketsQuantity} tickets
            </Typography>
          </div>
          <div className="flex flex-col gap-y-1 text-start">
            <Typography size="txl" className="max-w-[20ch] truncate font-bold">
              {isBundle && '+ '}
              {formatCurrencyV2(
                finalPrice,
                priceRate,
                currency,
                false,
                false
              )}{' '}
              x {ticketsQuantity}
            </Typography>
            <Typography size="tsm" className="font-medium text-gray-500">
              {isBundle && '+ '}
              {formatCurrencyV2(
                finalPrice * ticketsQuantity,
                priceRate,
                currency,
                false,
                false
              )}{' '}
              in total
            </Typography>
          </div>
        </div>
        <Divider />

        <div className="flex items-start gap-x-3">
          <Phone02 size="20" />
          <Typography size="tsm" className="font-medium text-gray-500">
            Mobile ticket: Scan your tickets from your mobile phone for this
            event. Do not print these tickets.
          </Typography>
        </div>
        <Button
          disabled={loading || isTicketSelectionConfirmed}
          className="w-full"
          onClick={handleConfirmSelection}
          isLoading={loading}
        >
          {isTicketSelectionConfirmed ? 'Selected' : 'Select'}
        </Button>
      </div>
    </div>
  );
}
