'use client';

type Props = {
  event: LeagueEvent;
};

import { Button, SearchMD } from '@v2/ui';

import { RoomConfiguration } from '@components/RoomConfigurator';
import { ILocationResult } from '@components/searchCity';
import { PACKAGE_STORAGE } from '@config/storageKeys';
import useStorage from '@hooks/useStorage';
import { LeagueEvent } from '@interfaces/APITravel';
import { IFormValues } from '@interfaces/buildPackage';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { formatISO } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useBuildPackagesStore } from 'src/stores/build-package-store';
import { useMediaQuery } from 'usehooks-ts';
import { invalidatePropertySearch } from '../../util';
import { BaseCheckInOutFilter } from './BaseFilters/BaseCheckinOutFilter';
import { BaseLocationFilter } from './BaseFilters/BaseLocationFilter';
import { BaseRoomsFilter } from './BaseFilters/BaseRoomsFilter';
import { MobileFilter } from './MobileFilter';

export type HeaderFormikProps = {
  location?: ILocationResult | undefined | null;
  hotelStartDate: string;
  hotelEndDate: string;
  roomConfigurations: RoomConfiguration[];
};

export function Header({ event }: Props) {
  const storage = useStorage();

  const { location, hotelEndDate, hotelStartDate, roomConfigurations } =
    useBuildPackagesStore((state) => ({
      location: state.location,
      hotelStartDate: state.hotelStartDate,
      hotelEndDate: state.hotelEndDate,
      roomConfigurations: state.roomConfigurations,
    }));

  const [currentPackageData] = useState<IFormValues>(
    JSON.parse(storage.getItem(PACKAGE_STORAGE) ?? '{}')
  );

  const formik = useFormik<HeaderFormikProps>({
    initialValues: {
      location: location,
      hotelStartDate: hotelStartDate,
      hotelEndDate: hotelEndDate,
      roomConfigurations: roomConfigurations,
    },
    enableReinitialize: true,
    onSubmit: (val) => {
      storage.setItem(
        PACKAGE_STORAGE,
        JSON.stringify({
          ...currentPackageData,
          ...formik.values,
          hotelStartDate: formatISO(new Date(formik.values.hotelStartDate)),
          hotelEndDate: formatISO(new Date(formik.values.hotelEndDate)),
        })
      );

      useBuildPackagesStore.setState({ ...val });
      useSearchHotelStore.setState({ roomSelections: [] });

      invalidatePropertySearch();
    },
  });

  const matches = useMediaQuery('(min-width: 1024px)');

  if (!matches) return <MobileFilter event={event} formik={formik} />;

  return (
    <>
      <form className="mb-12 bg-white p-8" onSubmit={formik.handleSubmit}>
        <h2 className="mb-8 text-3xl font-bold text-gray-800">Book a hotel</h2>
        <div className="flex items-center gap-x-3">
          <BaseLocationFilter formik={formik} />
          <BaseCheckInOutFilter formik={formik} event={event} />
          <BaseRoomsFilter formik={formik} />
          <Button className="h-[72px] shrink-0 px-6" type="submit">
            <SearchMD className="text-white" />
          </Button>
        </div>
      </form>
    </>
  );
}
