'use client';
import useEventLoader from '@hooks/use-event-loader';
import { LeagueEvent } from '@interfaces/APITravel';
import { useEffect } from 'react';
import { ArenaView, Sidebar } from './components';

import routes from '@config/routes';
import { useScrollToTopOnMount } from '@hooks/use-scroll-top-on-mount';
import useMinimumPrice from '@hooks/useMinimumPrice';
import { serverFetcher } from '@instance';
import { BaseProperty } from '@interfaces/expedia/property';
import { useBuildPackagesStore } from '@store/build-package-store';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { PageLoader } from '@v2/components';
import { cn } from '@v2/utils';
import { useTicketsStore } from 'src/stores/tickets-store';
import useSWR from 'swr';
import { useMediaQuery } from 'usehooks-ts';
import { TicketSelectionBottomHeader } from './components/TicketSelectionBottomHeader';

type PageProps = {
  event: LeagueEvent;
  eventId: string;
};

export function SearchTicketView({ event, eventId }: PageProps) {
  const { tickets, loading } = useEventLoader(eventId, true);
  useScrollToTopOnMount();

  const ticketGroups = tickets?.ticket_groups || [];
  const setTicketSelected = useTicketsStore((state) => state.setTicketSelected);
  const isBundle = useBuildPackagesStore((state) => state.isBundle);
  const ticketsQuantity = useTicketsStore((state) => state.ticketsQuantity);
  const distance = useSearchHotelStore(
    (state) => state.hotelFilters.distance[0]
  );
  const sortBy = useSearchHotelStore((state) => state.hotelFilters.sortBy);

  const base = useBuildPackagesStore((state) => ({
    startDate: state.hotelStartDate,
    endDate: state.hotelEndDate,
    location: state.location,
    roomConfigurations: state.roomConfigurations,
  }));

  const { data, isValidating } = useSWR<{
    error: [];
    properties: BaseProperty[];
  }>(
    routes.propertySearch,
    (url) =>
      serverFetcher(url, {
        body: {
          base,
          filters: {
            distance,
            sortBy,
          },
          options: {
            // A.S:Property not currently used when filtering properties
            requiredCurrency: 'USD',
            maxResult: 100,
            isBundle,
          },
        },
      }),
    { suspense: true, revalidateIfStale: false, revalidateOnFocus: false }
  );
  const { ticketPrice, hotelPrice } = useMinimumPrice(
    data?.properties,
    ticketGroups,
    ticketsQuantity
  );

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    () => setTicketSelected(null);
  }, []);

  if (loading || isValidating) return <PageLoader />;
  return (
    <>
      <section className="buy-package-height pt-2 md:mx-auto md:h-[720px] md:w-[1400px] md:px-20 md:pt-6">
        <div className="border-gary-50 flex h-auto flex-col-reverse rounded-lg border md:h-[720px] md:flex-row md:items-start">
          <>
            {' '}
            <aside
              className={cn(
                'h-full w-full md:w-[40%]',
                'border-gray-200 md:border-r'
              )}
            >
              <Sidebar
                ticketsList={ticketGroups}
                loading={loading}
                event={event}
              />
            </aside>
            {!isMobile && (
              <div className="h-full w-full bg-white md:w-[60%]">
                <ArenaView event={event} ticketGroups={ticketGroups} />
              </div>
            )}
          </>
        </div>
      </section>
      <TicketSelectionBottomHeader
        event={event}
        minHotelPrice={hotelPrice}
        minTicketPrice={ticketPrice}
      />
    </>
  );
}
