import { useCurrencyContext } from '@contexts/currency-context';
import { useBuildPackageBundle } from '@hooks/use-build-package-bundle';
import type { Rate, Room } from '@interfaces/expedia/property';
import { useSearchHotelStore } from '@store/search-hotel-store';
import { formatCurrencyV2 } from '@utils/format-currency-v2';
import { Bed, Coffee, CornerUpLeft, Typography, Users01, Wifi } from '@v2/ui';
import Image from 'next/image';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { OccupancyRates } from './OccupancyRates';
import { RoomDetailsDialog } from './RoomsDetailsDialog';

type RoomCardProps = {
  room: Room;
  rate: Rate;
  minimumPricePerOccupancy?: Record<string, number>;
  onSelectRoom: (
    room: Room,
    rate: Rate,
    ratePrice: number,
    occupancy,
    modifier: number
  ) => void;
};

export function RoomCard({
  room,
  rate,
  minimumPricePerOccupancy,
  onSelectRoom,
}: RoomCardProps) {
  const { occupancyPricing, refundable } = rate;

  const { description } = rate.bedgroups[0];
  const { priceRate, currency } = useCurrencyContext();

  const roomSelections = useSearchHotelStore((state) => state.roomSelections);

  const firstOccupancy = occupancyPricing[0];

  const roomPrice = useMemo(() => {
    const price = occupancyPricing.map((pricing) =>
      (
        +pricing.total.value -
        (minimumPricePerOccupancy?.[pricing.occupancy] ?? 0)
      ).toFixed(2)
    )[0];

    return formatCurrencyV2(price, priceRate, currency);
  }, [occupancyPricing, minimumPricePerOccupancy, priceRate, currency]);

  /*
   * 1 - Adds new room
   * -1 - removes  room
   */

  const handleSelectRoom = (
    price: number,
    occupancy: string,
    modifier: number
  ) => {
    onSelectRoom(room, rate, price, occupancy, modifier);
  };

  return (
    <div className="flex h-[604px] w-full max-w-[400px] flex-col rounded-lg border border-gray-200">
      <div className="relative min-h-[200px] w-full">
        <Image
          src={room.images[0]}
          className="rounded-t-lg object-cover"
          alt="Room photo"
          fill={true}
        />
      </div>

      <div className="roundend-b-lg flex h-full flex-col justify-between rounded-b-lg bg-white p-6">
        <div>
          <Typography size="tlg" className="mb-4 font-bold">
            {room.name}
            {/* {description} */}
          </Typography>
          <ul className="space-y-2.5">
            <li className="flex items-center gap-x-2.5">
              <Bed className="text-gray-500" />
              <span className="text-md font-medium">{description}</span>
            </li>
            <li className="flex items-center gap-x-2.5">
              <Users01 size="16" className="text-gray-500" />
              <span className="text-md font-medium">
                Sleeps {room.capacity.total}
              </span>
            </li>
            <li className="flex items-center gap-x-2.5">
              <CornerUpLeft size="16" className="text-gray-500" />
              <span className="text-md font-medium">
                {refundable ? 'Refundable' : 'Non-refundable'}
              </span>
              {/* <span>{rate.configuration.map(rate => )}</span> */}
            </li>

            <RoomDetailsDialog
              room={room}
              rate={rate}
              price={roomPrice}
              handleSelectRoom={handleSelectRoom}
              // @ts-ignore temp
              minimumPricePerOccupancy={minimumPricePerOccupancy}
            />
          </ul>
        </div>

        <div className="flex flex-row items-center justify-between">
          <div>
            <h4 className="text-3xl font-bold">+ {roomPrice}</h4>
            <span className="mt-1.5 text-sm font-medium text-gray-500">
              Per traveler <br /> Includes taxes & fees
            </span>
          </div>
          <div className="flex flex-col items-end space-y-3">
            <span className="text-sm font-medium text-gray-500">
              {rate.availableRooms} available
            </span>

            <OccupancyRates
              rateId={rate.id}
              // @ts-ignore temp
              minimumPricePerOccupancy={minimumPricePerOccupancy}
              occupancyRates={occupancyPricing}
              onSelect={handleSelectRoom}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
