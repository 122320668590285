'use client';
import { LeagueEvent } from '@interfaces/APITravel';
import { TicketGroup } from '@interfaces/ticketevolution/tikets';
import {
  Button,
  Checkbox,
  Divider,
  Label,
  Minus,
  Plus,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@v2/ui';
import { useState } from 'react';
import { useTicketsStore } from 'src/stores/tickets-store';
import { TicketList } from './TicketList';

type Props = {
  event: LeagueEvent;
  ticketsList: TicketGroup[];
  loading: boolean;
};

import { useMediaQuery } from '@uidotdev/usehooks';
import { memo } from 'react';
import { MobileMapFilter, MobileTicketFilters } from './MobileTicketFilters';
import { TicketPriceRangeSlider } from './TicketPriceRangeSlider';

export const Sidebar = memo(function Sidebar({
  ticketsList,
  loading,
  event,
}: Props) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <div>
        <div className="bg-white">
          <div className="border-b border-gray-300 p-4 pb-5">
            <Header />
          </div>
          <MainContent
            ticketsList={ticketsList}
            loading={loading}
            event={event}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="bg-white">
        <div className="px-6 py-8">
          <div className="mb-6">
            <Header />
            <Divider className="mt-8" />
          </div>
          <TicketPriceRangeSlider ticketsList={ticketsList} />
        </div>

        <MainContent ticketsList={ticketsList} loading={loading} />
      </div>
    </div>
  );
});

function Header() {
  const [ticketsQuantity, setTicketsQuantity] = useTicketsStore((state) => [
    state.ticketsQuantity,
    state.setTickets,
  ]);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const setTicketSelected = useTicketsStore((state) => state.setTicketSelected);

  return (
    <div className="flex flex-col items-center justify-center gap-y-3 md:gap-y-4">
      <h3 className="text-xl font-bold text-gray-800 md:text-3xl">
        How many tickets?
      </h3>
      <div className="flex items-center justify-start gap-x-2">
        <Button
          size="sm"
          variant="secondary"
          onClick={() => {
            setTicketsQuantity('decrement');
            setTicketSelected(null);
            useTicketsStore.setState({ isTicketSelectionConfirmed: false });
          }}
          className="h-8 w-8 md:h-[36px] md:w-[36px]"
          type="button"
        >
          <Minus size={isMobile ? '16' : '20'} />
        </Button>
        <span className="min-w-10 text-center text-xl font-bold text-gray-800 md:text-3xl">
          {ticketsQuantity}
        </span>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={() => {
            setTicketsQuantity('increment');
            setTicketSelected(null);
            useTicketsStore.setState({ isTicketSelectionConfirmed: false });
          }}
          className="h-8 w-8 md:h-[36px] md:w-[36px]"
        >
          <Plus size={isMobile ? '16' : '20'} />
        </Button>
      </div>
    </div>
  );
}

function MainContent({
  loading,
  ticketsList,
  event,
}: {
  ticketsList: TicketGroup[];
  loading: boolean;
  event: LeagueEvent;
}) {
  const toggleBoolean = useTicketsStore((state) => state.toggleBoolean);
  const sortBy = useTicketsStore((state) => state.sortby);
  const isOnlyElectronic = useTicketsStore((state) => state.isOnlyElectronic);
  const [tab, setTab] = useState('lowestPrice');

  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <div className="bg-gray-50">
        <div className="flex items-center gap-x-2 p-4">
          <MobileTicketFilters ticketsList={ticketsList} />
          <MobileMapFilter event={event} ticketGroups={ticketsList} />
        </div>
        <TicketList
          list={[...ticketsList].sort((a, b) =>
            sortBy === 'bestSeats'
              ? b.retail_price - a.retail_price
              : a.retail_price - b.retail_price
          )}
          loading={loading}
        />
      </div>
    );
  }

  return (
    <Tabs
      value={tab}
      onValueChange={(type: string) => setTab(type)}
      className="bg-white"
    >
      <TabsList variant="outline">
        <TabsTrigger
          variant="outline"
          value="lowestPrice"
          className="w-full pb-4 pt-0"
        >
          Lowest price
        </TabsTrigger>
        <TabsTrigger
          variant="outline"
          value="bestSeats"
          className="w-full pb-4 pt-0"
        >
          Best seats
        </TabsTrigger>
      </TabsList>
      <div className="flex items-center gap-x-3 bg-gray-50 px-6 py-4">
        <Checkbox
          className="rounded-md bg-white"
          size="sm"
          checked={isOnlyElectronic}
          onCheckedChange={() => toggleBoolean('isOnlyElectronic')}
        />
        <Label className="font-semibold text-gray-800">
          Electronic tickets only
        </Label>
      </div>
      <TabsContent value="lowestPrice" className="mt-0 bg-gray-50">
        <TicketList
          list={[...ticketsList].sort(
            (a, b) => a.retail_price - b.retail_price
          )}
          loading={loading}
        />
      </TabsContent>
      <TabsContent value="bestSeats" className="mt-0 bg-gray-50">
        <TicketList
          list={[...ticketsList].sort(
            (a, b) => b.retail_price - a.retail_price
          )}
          loading={loading}
        />
      </TabsContent>
    </Tabs>
  );
}
